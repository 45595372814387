import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import Box from '@material-ui/core/Box';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Button from '@material-ui/core/Button';
import AppTextInput from '../../../@jumbo/components/Common/formElements/AppTextInput'
import Grid from '@material-ui/core/Grid';
import API from '../../../services/api';
import { Typography } from '@material-ui/core';

const breadcrumbs = [
  { label: 'Home', link: '/' },
  { label: 'Sample Page', isActive: true },
];

const MainPage = () => {
  const [currStep, setCurrStep] = useState(0)
  const [code, setCode] = useState('')
  const dispatch = useDispatch();
  const history = useHistory();
  const { errors, uniqueCode } = useSelector(({ auth }) => auth);
  const checkUniqueCode = () => {
    if (!code) return;
    dispatch(API.checkUniqueToken(code))
  }

  useEffect(() => {
    if (uniqueCode) {
      setCurrStep(1)
    }
  }, [uniqueCode])

  return (
    <PageContainer breadcrumbs={breadcrumbs}>
      <GridContainer>
        <Grid item xs={12}>
          <Box>
            {
              !currStep && (
                <Box display="flex" flexDirection="column" alignItems="start">
                  <Typography style={{ marginBottom: 10 }}>
                    <IntlMessages id="pages.samplePage.description" />
                  </Typography>
                  <AppTextInput
                      id="code"
                      label="Code"
                      name="code"
                      type="text"
                      required
                      variant="outlined"
                      value={code}
                      fullWidth={false}
                      error={errors['unique_code']}
                      helperText={errors['unique_code']}
                      onChange={e => setCode(e.target.value)}
                      style={{ marginBottom: 15 }}
                  />
                  <Button onClick={checkUniqueCode} variant="contained" color="primary">Send</Button>
                </Box>
              )
            }
            {
              currStep === 1 && (
                <>
                  <Box style={{ marginBottom: 15 }}>
                    <IntlMessages id="dashboard.welcomeMessage" />
                  </Box>
                  <Box style={{ marginBottom: 15 }}>
                    <IntlMessages id="dashboard.description" />
                  </Box>
                  <Box style={{ marginBottom: 15 }}>
                    <IntlMessages id="dashboard.description2" />
                  </Box>
                  <Box style={{ marginBottom: 30 }}>
                    <IntlMessages id="dashboard.description3" />
                  </Box>
                  <Box>
                    <Button variant="contained" color="primary" onClick={() => setCurrStep(2)}>
                      <IntlMessages id="dashboard.learnMore" />
                    </Button>
                  </Box>
                </>
              )
            }
            {
              currStep === 2 && (
                <>
                  <Box style={{ marginBottom: 15 }}>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                  </Box>
     
                  <Box>
                    <Button variant="contained" color="primary" style={{ marginRight: 15 }}>
                      Deny
                    </Button>
                    <Button variant="contained" color="primary"  onClick={() => history.push('/profile')}>
                      Approve
                    </Button>
                  </Box>
                </>
              )
            }

          </Box>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default MainPage;
