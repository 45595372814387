import {
  SEND_FORGET_PASSWORD_EMAIL,
  UPDATE_USER,
  UPDATE_PHONE_USER,
  UPDATE_AUTH_USER,
  ERROR_USER,
  UPDATE_LOAD_USER,
  UPDATE_USER_SUCCESS,
  REMIND_TIME_CHANGE,
  REQUEST_REMIND_TIME_CHANGE,
  SET_UNIQUE_CODE
} from "../../@jumbo/constants/ActionTypes";

const uniqueCode = localStorage.getItem("uniqueCode");

const INIT_STATE = {
  authUser: null,
  codeRequested: false,
  loadUser: false,
  errors: {},
  send_forget_password_email: false,
  user: {},
  reminderUpdate: false,
  uniqueCode: uniqueCode
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER: {
      return {
        ...state,
        authUser: action.payload,
        loadUser: true
      };
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        errors: {}
      };
    }
    case UPDATE_AUTH_USER: {
      return {
        ...state,
        authUser: action.payload,
        loadUser: true
      };
    }
    case SET_UNIQUE_CODE: {
      localStorage.setItem("uniqueCode", action.payload.unique_code);
      return {
        ...state,
        errors: {},
        uniqueCode: action.payload.unique_code
      };
    }
    case REMIND_TIME_CHANGE: {
      return {
        ...state
      };
    }
    case REQUEST_REMIND_TIME_CHANGE: {
      return {
        ...state,
        reminderUpdate: true
      };
    }
    case ERROR_USER: {
      return {
        ...state,
        errors: action.payload
      };
    }
    case UPDATE_PHONE_USER: {
      return {
        ...state,
        codeRequested: true,
        loadUser: true
      };
    }
    case UPDATE_LOAD_USER: {
      return {
        ...state,
        loadUser: action.payload
      };
    }
    case SEND_FORGET_PASSWORD_EMAIL: {
      return {
        ...state,
        send_forget_password_email: action.payload
      };
    }
    default:
      return state;
  }
};
