import React, {useEffect, useState} from "react";
import moment from "moment";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import GridContainer from "../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import Button from "@material-ui/core/Button";
import {OutlinedInput} from "@material-ui/core";
import AppTextInput from "../../../@jumbo/components/Common/formElements/AppTextInput";
import AppDatePicker from "../../../@jumbo/components/Common/formElements/AppDatePicker";
import AppSelectBox from "../../../@jumbo/components/Common/formElements/AppSelectBox";
import {KeyboardTimePicker} from "@material-ui/pickers";
import {genders, timezones, states, diseases} from "./constants";
import API from "../../../services/api";
import TextMaskCustom from "./TextMaskCustom";

const Profile = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {codeRequested, errors, user, uniqueCode} = useSelector(
    ({auth}) => auth
  );
  const [ reminderOpen, setReminderOpen ] = useState(false);
  const [ state, setFormState ] = useState({
    unique_code: uniqueCode,
    first_name: "",
    last_name: "",
    birthdate: null,
    gender: "",
    state: "",
    timezone: "",
    disease: "",
    reminder: null,
    phone_number: null,
    phone_verification_code: ""
  });

  const handleSubmit = e => {
    e.preventDefault();
    const data = {...state};
    data.reminder = moment(data.reminder).format("HH:mm");
    data.birthdate = moment(data.birthdate).format("MM/YYYY");
    data.timezone = parseInt(data.timezone);
    data.phone_number = "+1 " + state["phone_number"];
    dispatch(API.setUserData(data));
  };

  const verifyPhoneNumber = () => {
    dispatch(API.requestCode({phone_number: "+1 " + state["phone_number"]}));
  };

  useEffect(
    () => {
      if (Object.keys(user).length !== 0) {
        history.push("/conditions");
      }
    },
    [ user ]
  );

  const handleChange = event => {
    setFormState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  return (
    <PageContainer>
      <GridContainer>
        <Grid item xs={12} maxWidth="500px">
          <form onSubmit={handleSubmit}>
            <Typography style={{marginBottom: 10}}>
              <IntlMessages id="profile.description" />
            </Typography>
            {errors["unique_code"] && (
              <AppTextInput
                id="unique_code"
                label="Unique code"
                name="unique_code"
                type="text"
                required
                variant="outlined"
                error={errors["unique_code"]}
                helperText={errors["unique_code"]}
                value={state["unique_code"]}
                onChange={handleChange}
                style={{marginBottom: 15}}
              />
            )}
            <AppTextInput
              id="first_name"
              label="First Name"
              name="first_name"
              type="text"
              placeholder="What would you like to be called?"
              required
              variant="outlined"
              error={errors["first_name"]}
              helperText={errors["first_name"]}
              value={state["first_name"]}
              onChange={handleChange}
              style={{marginBottom: 15}}
            />

            <AppTextInput
              id="last_name"
              label="Last Name"
              name="last_name"
              type="text"
              placeholder="Enter your last name"
              variant="outlined"
              error={errors["last_name"]}
              helperText={errors["last_name"]}
              value={state["last_name"]}
              onChange={handleChange}
              style={{marginBottom: 15}}
            />

            <AppDatePicker
              id="birthdate"
              label="Birth Month and Year"
              variant="outlined"
              inputVariant="outlined"
              name="birthdate"
              format="MM/YYYY"
              views={[ "month", "year" ]}
              required
              placeholder="mm/yyyy"
              value={state["birthdate"]}
              error={errors["birthdate"]}
              helperText={errors["birthdate"]}
              onChange={e => setFormState({...state, birthdate: moment(e)})}
              style={{marginBottom: 15}}
            />

            <AppSelectBox
              data={genders}
              id="gender"
              name="gender"
              label="Gender"
              variant="outlined"
              required
              value={state["gender"]}
              error={errors["gender"]}
              helperText={errors["gender"]}
              style={{marginBottom: 15}}
              onChange={handleChange}
              renderRow={(item, index) => (
                <MenuItem key={index} value={item.slug}>
                  {item.label}
                </MenuItem>
              )}
            />

            <AppSelectBox
              data={states}
              id="state"
              name="state"
              label="State"
              variant="outlined"
              required
              value={state["state"]}
              error={errors["state"]}
              helperText={errors["state"]}
              style={{marginBottom: 15}}
              onChange={handleChange}
              renderRow={(item, index) => (
                <MenuItem key={index} value={item.slug}>
                  {item.label}
                </MenuItem>
              )}
            />

            <AppSelectBox
              data={timezones}
              id="timezone"
              name="timezone"
              label="Timezone"
              variant="outlined"
              required
              value={state.timezone}
              error={errors["timezone"]}
              helperText={errors["timezone"]}
              style={{marginBottom: 15}}
              onChange={handleChange}
              renderRow={(item, index) => (
                <MenuItem key={index} value={item.slug}>
                  {item.label}
                </MenuItem>
              )}
            />

            <AppSelectBox
              data={diseases}
              id="disease"
              name="disease"
              label="Disease"
              variant="outlined"
              required
              value={state["disease"]}
              error={errors["disease"]}
              helperText={errors["disease"]}
              style={{marginBottom: 15}}
              onChange={handleChange}
              renderRow={(item, index) => (
                <MenuItem key={index} value={item.slug}>
                  {item.label}
                </MenuItem>
              )}
            />

            <Typography style={{marginBottom: 10}}>
              <IntlMessages id="profile.reminder.label" />
            </Typography>

            <KeyboardTimePicker
              margin="normal"
              id="reminder"
              label="Reminder"
              inputVariant="outlined"
              fullWidth={true}
              required
              allowKeyboardControl={false}
              keyboardIcon={<QueryBuilderIcon />}
              value={state["reminder"]}
              error={errors["reminder"]}
              placeholder="hh:mm _"
              helperText={errors["reminder"]}
              onChange={e => setFormState({...state, reminder: moment(e)})}
              InputProps={{readOnly: true}}
              open={reminderOpen}
              onClose={() => setReminderOpen(false)}
              onClick={e => {
                setReminderOpen(!reminderOpen);
              }}
            />

            <Box flexbox>
              <Typography style={{marginBottom: 10}}>
                <IntlMessages id="profile.phone.label" />
              </Typography>
              <Box>
                <Box
                  display="flex"
                  alignItems="start"
                  style={{marginBottom: 15}}
                >
                  <Box
                    display="flex"
                    alignItems="start"
                    flexDirection="column"
                    style={{marginBottom: 15}}
                  >
                    <OutlinedInput
                      id="phone_number"
                      name="phone_number"
                      required
                      value={state.phone_number}
                      error={errors["phone_number"]}
                      helperText={errors["phone_number"]}
                      onChange={handleChange}
                      inputComponent={TextMaskCustom}
                      style={{marginRight: 30}}
                    />
                    {errors["phone_number"] && (
                      <FormHelperText error={errors["phone_number"]}>
                        {errors["phone_number"]}
                      </FormHelperText>
                    )}
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    type="button"
                    onClick={verifyPhoneNumber}
                  >
                    Verify
                  </Button>
                </Box>
                {codeRequested && (
                  <Box display="flex" flexDirection="column" alignItems="start">
                    <Typography style={{marginBottom: 10}}>
                      <IntlMessages id="profile.phone.verify" />
                    </Typography>

                    <AppTextInput
                      id="phone_verification_code"
                      label="Code"
                      name="phone_verification_code"
                      type="text"
                      variant="outlined"
                      required
                      value={state["phone_verification_code"]}
                      error={errors["phone_verification_code"]}
                      helperText={errors["phone_verification_code"]}
                      onChange={handleChange}
                      fullWidth={false}
                      style={{marginBottom: 15}}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            {codeRequested && (
              <Button variant="contained" color="primary" type="submit">
                Submit
              </Button>
            )}
          </form>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Profile;
