import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS, UPDATE_PHONE_USER, UPDATE_USER, ERROR_USER,
  UPDATE_USER_SUCCESS, REQUEST_REMIND_TIME_CHANGE, REMIND_TIME_CHANGE, SET_UNIQUE_CODE } from '../../@jumbo/constants/ActionTypes';

export const fetchSuccess = () => {
  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS,
    });
  };
};

export const fetchError = error => {
  return dispatch => {
    dispatch({
      type: FETCH_ERROR,
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return dispatch => {
    dispatch({
      type: FETCH_START,
    });
  };
};


export const requestCodeSuccess = () => {
  return dispatch => {
    dispatch({
      type: UPDATE_PHONE_USER,
    });
  };
}; 

export const updateProfileSuccess = () => {
  return dispatch => {
    dispatch({
      type: UPDATE_USER,
    });
  };
}; 

export const userError = errors => {
  return dispatch => {
    dispatch({
      type: ERROR_USER,
      payload: errors
    });
  };
};

export const setUserDataSuccess = data => {
  return dispatch => {
    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data
    });
  };
};

export const requestRemindTimeChange = () => {
  return dispatch => {
    dispatch({
      type: REQUEST_REMIND_TIME_CHANGE
    });
  };
};

export const remindTimeChange = data => {
  return dispatch => {
    dispatch({
      type: REMIND_TIME_CHANGE,
      payload: data
    });
  };
};

export const setUniqueCode = data => {
  return dispatch => {
    dispatch({
      type: SET_UNIQUE_CODE,
      payload: data
    });
  };
};