import axios from './config';
import { userError, fetchError, fetchStart, fetchSuccess, requestCodeSuccess, setUserDataSuccess,
  requestRemindTimeChange, remindTimeChange, setUniqueCode } from '../../redux/actions';

const API = {
  setUserData: (data) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('/user', data)
        .then(({data}) => {          
          if (!data.errors) {
            dispatch(fetchSuccess());
            dispatch(setUserDataSuccess(data));
          } else if (data.errors) {
            dispatch(fetchError())
            dispatch(userError(data.errors));
          }
        })
        .catch(function(error) {
          dispatch(fetchError())
          dispatch(userError(error.message));
        });
    };
  },

  requestCode: (phone) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('/requestcode', phone)
        .then(({ data }) => {
          if (!data.errors) {
            dispatch(fetchSuccess());
            dispatch(requestCodeSuccess());
          } else if (data.errors) {
            dispatch(fetchError())
            dispatch(userError(data.errors));
          }
        })
        .catch(function(error) {
          dispatch(fetchError())
          dispatch(userError(error.message));
        });
    };
  },


  checkPermissionToken: (permissionToken) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post(`/checkpermissiontoken`, { permission_token: permissionToken })
        .then(({ data }) => {
          if (!data.errors) {
            dispatch(fetchSuccess());
            dispatch(requestRemindTimeChange());
          } else if (data.errors) {
            dispatch(fetchError())
            dispatch(userError(data.errors));
          }
        })
        .catch(function(error) {
          dispatch(fetchError())
          dispatch(userError(error.message));
        });
    };
  },

  checkUniqueToken: (uniqueToken) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post(`/checkuniquecode`, { unique_code: uniqueToken })
        .then(({ data }) => {
          if (!data.errors) {
            dispatch(fetchSuccess());
            dispatch(setUniqueCode(data));
          } else if (data.errors) {
            dispatch(fetchError())
            dispatch(userError(data.errors));
          }
        })
        .catch(function(error) {
          dispatch(fetchError())
          dispatch(userError(error.message));
        });
    };
  },


  changeReminderTime: (permissionToken, reminder) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post(`/user/reminder/${permissionToken}`, { reminder: reminder })
        .then(({ data }) => {
          if (!data.errors) {
            dispatch(fetchSuccess());
            dispatch(remindTimeChange());
          } else if (data.errors) {
            dispatch(fetchError())
            dispatch(userError(data.errors));
          }
        })
        .catch(function(error) {
          dispatch(fetchError())
          dispatch(userError(error.message));
        });
    };
  },

};

export default API;
