import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MainPage from './Pages/MainPage';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';
import Profile from './Pages/Profile';
import Conditions from './Pages/Conditions';
import Reminder from './Pages/Reminder';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/'}  />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/" exact={true} component={MainPage} />
        <Route path="/profile" component={Profile} />
        <Route path="/user/reminder/:id" component={Reminder} />
        <Route path="/conditions" component={Conditions} />
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
