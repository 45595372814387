import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { KeyboardTimePicker } from '@material-ui/pickers';
import GridContainer from '../../../@jumbo/components/GridContainer';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom'
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import API from '../../../services/api';

const Reminder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [reminder, setReminderTime] = useState(moment())
  const { errors, reminderUpdate } = useSelector(({ auth }) => auth);

  useEffect(() => {
    dispatch(API.checkPermissionToken(id))
  }, [])

  const handleSubmit = () => {
    const reminderTime = moment(reminder).format('HH:mm')
    dispatch(API.changeReminderTime(id, reminderTime))
  }

  return (
    <PageContainer>
      <GridContainer>
        <Grid item xs={12} maxWidth='500px'>
            <Box>
            {reminderUpdate ? (
                <>  
                    <Box>
                        <Typography>
                            Reminder time:
                        </Typography>
                        <KeyboardTimePicker
                            margin="normal"
                            id="reminder"
                            label="Reminder"
                            inputVariant="outlined"
                            fullWidth={false}
                            required
                            value={reminder}
                            error={errors['user_id']}
                            helperText={errors['user_id']}
                            onChange={e => setReminderTime(moment(e)) }
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                        />
                    </Box>
                    <Button variant="contained" color="primary" type="button" onClick={handleSubmit}>Update</Button>
                </>
            ): (
                <Typography>
                    User not found or token is expired.
                </Typography>
            )}
            
            </Box>

        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Reminder;