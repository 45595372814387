import React from "react";
import MaskedInput from "react-text-mask";

export default props => {
  const {inputRef, ...other} = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={true}
      keepCharPositions={true}
      key="phone_number-input"
      id="phone_number-input-field"
      mask={[
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
    />
  );
};
