export const genders = [
    { label: 'Male', slug: 'Male', },
    { label: 'Female', slug: 'Female' },
    { label: 'Non-binary', slug: 'Non-binary' },
    { label: 'Not listed', slug: 'Not listed' },
];

export const timezones = [
    { label: 'Eastern Time (New York)', slug: '-5' },
    { label: 'Central Time (Chicago)', slug: '-6' },
    { label: 'Mountain Time (Denver)', slug: '-7' },
    { label: 'Mountain Time (Phoenix)', slug: '+13' },
    { label: 'Pacific Time (Los Angeles)', slug: '-8' },
    { label: 'Alaska Time (Anchorage)', slug: '-9' },
    { label: 'Hawaii Time (Honolulu)', slug: '-10' },
];

export const states = [
    {
        label: "Alabama",
        slug: "AL"
    },
    {
        label: "Alaska",
        slug: "AK"
    },
    {
        label: "Arizona",
        slug: "AZ"
    },
    {
        label: "Arkansas",
        slug: "AR"
    },
    {
        label: "California",
        slug: "CA"
    },
    {
        label: "Colorado",
        slug: "CO"
    },
    {
        label: "Connecticut",
        slug: "CT"
    },
    {
        label: "Delaware",
        slug: "DE"
    },
    {
        label: "Florida",
        slug: "FL"
    },
    {
        label: "Georgia",
        slug: "GA"
    },
    {
        label: "Hawaii",
        slug: "HI"
    },
    {
        label: "Idaho",
        slug: "ID"
    },
    {
        label: "Illinois",
        slug: "IL"
    },
    {
        label: "Indiana",
        slug: "IN"
    },
    {
        label: "Iowa",
        slug: "IA"
    },
    {
        label: "Kansas",
        slug: "KS"
    },
    {
        label: "Kentucky",
        slug: "KY"
    },
    {
        label: "Louisiana",
        slug: "LA"
    },
    {
        label: "Maine",
        slug: "ME"
    },
    {
        label: "Maryland",
        slug: "MD"
    },
    {
        label: "Massachusetts",
        slug: "MA"
    },
    {
        label: "Michigan",
        slug: "MI"
    },
    {
        label: "Minnesota",
        slug: "MN"
    },
    {
        label: "Mississippi",
        slug: "MS"
    },
    {
        label: "Missouri",
        slug: "MO"
    },
    {
        label: "Montana",
        slug: "MT"
    },
    {
        label: "Nebraska",
        slug: "NE"
    },
    {
        label: "Nevada",
        slug: "NV"
    },
    {
        label: "New Hampshire",
        slug: "NH"
    },
    {
        label: "New Jersey",
        slug: "NJ"
    },
    {
        label: "New Mexico",
        slug: "NM"
    },
    {
        label: "New York",
        slug: "NY"
    },
    {
        label: "North Carolina",
        slug: "NC"
    },
    {
        label: "North Dakota",
        slug: "ND"
    },
    {
        label: "Ohio",
        slug: "OH"
    },
    {
        label: "Oklahoma",
        slug: "OK"
    },
    {
        label: "Oregon",
        slug: "OR"
    },
    {
        label: "Pennsylvania",
        slug: "PA"
    },
    {
        label: "Rhode Island",
        slug: "RI"
    },
    {
        label: "South Carolina",
        slug: "SC"
    },
    {
        label: "South Dakota",
        slug: "SD"
    },
    {
        label: "Tennessee",
        slug: "TN"
    },
    {
        label: "Texas",
        slug: "TX"
    },
    {
        label: "Utah",
        slug: "UT"
    },
    {
        label: "Vermont",
        slug: "VT"
    },
    {
        label: "Virginia",
        slug: "VA"
    },
    {
        label: "Washington",
        slug: "WA"
    },
    {
        label: "West Virginia",
        slug: "WV"
    },
    {
        label: "Wisconsin",
        slug: "WI"
    },
    {
        label: "Wyoming",
        slug: "WY"
    }
];

export const diseases = [
    { label: 'Diabetes', slug: 'Diabetes' },
    { label: 'Heart', slug: 'Heart' },
    { label: 'Hypertension', slug: 'Hypertension' },
    { label: 'Depression', slug: 'Depression' },
    { label: 'Anxiety', slug: 'Anxiety' },
]; 