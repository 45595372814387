import React from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import Grid from '@material-ui/core/Grid';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import { Typography } from '@material-ui/core';

const Conditions = () => {
  return (
    <PageContainer>
      <GridContainer>
        <Grid item xs={12} maxWidth='500px'>
            <Typography>
                Diabetes
            </Typography>
            <Typography>
                Lorem ipsum
            </Typography>
            <Typography>
                Heart
            </Typography>
            <Typography>
                Lorem ipsum
            </Typography>
            <Typography>
                Hypertension
            </Typography>
            <Typography>
                Lorem ipsum
            </Typography>
            <Typography>
                Depression
            </Typography>
            <Typography>
                Lorem ipsum
            </Typography>
            <Typography>
                Anxiety
            </Typography>
            <Typography>
                Lorem ipsum
            </Typography>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default Conditions;